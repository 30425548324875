import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { homeSliderImage, homeSlider, eventList, eventListDate, eventListEntry, eventListSec } from "./index.module.scss"

const IndexPage = () => {

  const queryResult = useStaticQuery(graphql`
    query HomePageQuery {
      allPagesJson(filter: {name: {eq: "homepage"}}) {
        edges {
          node {
            id
            name
            content {
              headline
            }          
          }
        }
      }
    }
  `)

  const data = queryResult.allPagesJson.edges[0].node
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false
  }

  return (
    <Layout id="home">
      <Seo title="Home" />
      <h1>Weihnachtskrippe Dürrenwaldstetten</h1>
      <Slider {...settings} className={homeSlider}>
        <StaticImage
          src="../images/aktuelles/krippe-duerrenwaldstetten-uebersicht.jpg"
          className={homeSliderImage}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Krippe Dürrenwaldstetten Übersicht"
          style={{ marginBottom: `1.45rem` }}
          aspectRatio={16 / 9}
        />
        <StaticImage
          src="../images/aktuelles/krippe-duerrenwaldstetten-stall.jpg"
          className={homeSliderImage}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Krippe Dürrenwaldstetten Stall"
          aspectRatio={16 / 9}
        />
        <StaticImage
          src="../images/aktuelles/krippe-duerrenwaldstetten-herodes.jpg"
          className={homeSliderImage}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Krippe D+rrenwaldstetten Herodes"
          aspectRatio={16 / 9}
        />
      </Slider>
      <p className="normal"><b>Herzlich Willkommen</b> auf unserer Internetseite. Hier finden Sie aktuelle Informationen rund
        um die Weihnachtskrippe.<br />
        Unsere Weihnachtskirche kann täglich von 9 Uhr bis 17 Uhr während der Adventszeit <b>01.12. - 02.02.</b> (Lichtmess)
        besichtigt werden. <br />
        Bei Besuch von Gruppen besteht die Möglichkeit eine Krippenführung zu vereinbaren (<a href="tel:073761499">07376/1499</a>). Die Kripplesgruppe freut sich
        auf ihren Besuch.</p>
      <h2>Aktuelles 2024 / 2025</h2>
      <ul className={eventList}>
      <li className={eventListEntry}>
          <div className={eventListDate}>10.11.2024</div>
          <div className={eventListSec}><h4>Krippenaufbau hat begonnen</h4>
          <p>Nun ist es wieder soweit. Die Kripplesgruppe hat schon begonnen, die seit 20 Jahren immer wieder erweiterte Weihnachtskrippe, aufzubauen. 
            Dieses Jahr wollen wir unseren Besuchern und Fans ab dem 1. Advent die Möglichkeit geben, unsere noch größere und um eine Szene erweiterte Krippe, länger zu besichtigen.</p>
            <p>Vom <b>1. Dez. 2024 - 2. Feb. 2025 von 9.00 Uhr - 17.00 Uhr</b> ist die Kirche für Besucher geöffnet. Sie dürfen gespannt sein auf unsere Erweiterung.<br/> 
            Beim Wunsch einer Führung rufen Sie einfach unter der Telefonnummer <a href="tel:073761499">07376/1499</a> an.</p>
            <b>Eure Kripplesgruppe</b>
            <br/><br/>
          </div><br/>
          <hr />
        </li>
      <li className={eventListEntry}>
          <div className={eventListDate}>24.03.2024</div>
          <div className={eventListSec}><h4>20 Jahre Weihnachtskrippe Dürrenwaldstetten</h4>
          <p>Die Weihnachtszeit kommt und geht. Leider ist die Saison 2023/24 schon wieder vorbei. Dennoch möchten wir,
          die Kripplesgruppe, uns recht herzlich bedanken bei den Besuchern und Fans unserer schönen Weihnachtskrippe für die zahlreichen Besuche. </p>
          <p>Als offenes Geheimnis möchten wir erwähnen, daß wir zur nächsten Weihnachtszeit ein Jubiläum feiern können und zwar das 20 jährige
             Bestehen der Weihnachtskrippe. Hier möchten wir unsere Besucher natürlich überraschen. Aber mehr möchten wir noch nicht verraten, 
             dennoch dürfen Sie auf eine Überraschung sehr gespannt sein.</p>
            <b>Eure Kripplesgruppe</b>
            <br/><br/>
          </div><br/>
          <hr />
        </li>
      {/*<li className={eventListEntry}>
          <div className={eventListDate}>23.01.2024</div>
          <div className={eventListSec}><h4>Lichterfest am 02.02.2024 um 19:30 Uhr</h4>
            Die Kripplesgruppe veranstaltet dieses Jahr wieder am 02.02.2024 um 19:30 Uhr das schon zur Tradition
            gewordene Lichterfest in der Pfarrkirche Sankt Jakobus.
            <br /><br />
            <StaticImage
              src="../images/aktuelles/krippe-duerrenwaldstetten-ansicht-tempel-prophet.jpg"
              className={homeSliderImage}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Krippe D+rrenwaldstetten Herodes"
              aspectRatio={16 / 9}
            /><br /><br />
            <b>Die Kripplesgruppe freut sich auf Euren Besuch.</b>
          </div><br />
          <hr />
        </li>*/}
        {/*<li className={eventListEntry}>
          <div className={eventListDate}>02.01.2024</div>
           <div className={eventListSec}> <h4>Krippenführung unterstützt durch den Männergesangsverein Laiz am 06.01.2024</h4>
           Der Männergesangverein Laiz begleitet und unterstützt den Krippenverein Dürrenwaldstetten am Dreikönigstag am 06.01.2024 um 14:00 Uhr 
           in der St. Jakobiskirche  bei einer Krippenführung mit seinen schönsten Weihnachtsliedern.<br/><br/>
           Gönnen Sie sich ein ergreifendes Weihnachtsflair.
           Der MGV-Laiz und die Kripplesgruppe würden sich über Ihren Besuch freuen.<br/><br/>
           <b>Vorankündigung:</b> Die Kripplesgruppe beabsichtigt die Ausstellungszeit der Weihnachtskrippe mit einer Schlußführung untermalt von schönen Harfenklängen zu beenden.<br/>
           Der Termin wird noch bekannt gegeben.
          </div><br />
          <hr />
        </li>*/}
        {/*<li className={eventListEntry}>
          <div className={eventListDate}>02.02.2023 / 19:00 Uhr</div>
          <div className={eventListSec}>Die Kripplesgruppe veranstaltet dieses Jahr wieder das schon zur Tradition
            gewordene Lichterfest in der Pfarrkirche Sankt Jakobus.
            Dieses Jahr wird die Krippenführung durch den Kirchenchor Dürrenwaldstetten-Ittenhausen unter der Leitung von
            Bianca Bossler umrahmt.<br /><br />
            <StaticImage
              src="../images/aktuelles/krippe-duerrenwaldstetten-ansicht-tempel-prophet.jpg"
              className={homeSliderImage}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Krippe D+rrenwaldstetten Herodes"
              aspectRatio={16 / 9}
            /><br /><br />
            <b>Die Kripplesgruppe freut sich auf Euren Besuch.</b>
          </div><br />
          <hr />
        </li> */}
      </ul>
      {/* <ul className={eventList}>
        <li className={eventListEntry}>
          <div className={eventListDate}>27.12.2021</div>
          <div className="event-list-sec">Führung, Dauer ca. 60min</div>
        </li>
        <li className={eventListEntry}>
          <div className={eventListDate}>27.12.2021</div>
          <div className="event-list-sec">Führung, Dauer ca. 60min</div>
        </li>
        <li className={eventListEntry}>
          <div className={eventListDate}>27.12.2021</div>
          <div className="event-list-sec">Führung, Dauer ca. 60min</div>
        </li>
      </ul>
      */}
      {/* <p className="normal">
        Auf Grund der Corona Pandemie konnten wir die Weihnachtskrippe die letzten 2 Jahre nicht aufstellen sondern nur
        einen Teil (Geburtsszene).<br />
        Die momentane Situation und die gültigen Hygienevorschriften werden es erlauben dieses Jahr (2022-2023) die
        gesamte Krippenlandschaft wieder aufzubauen.<br /><br />
        Es werden auch wieder Führungen angeboten und die Kripplesgruppe freut sich auf Ihren Besuch. Führungen können
        per Telefon (<a href="tel:073761499">07376/1499</a>)
        oder E-Mail (<a href="mailto:norbert.spoth@web.de">norbert.spoth@web.de</a>) reserviert werden.
    </p> */}
      <h2>Adresse</h2>
      <p className="normal centered">
        St. Jakobus<br />
        Am Kirchberg 19 <br />
        88515 Dürrenwaldstetten<br />
        Gemeinde Langenenslingen
      </p>
      <h2>Kontakt</h2>
      <p className="normal centered">
        Norbert Spoth<br />Tel. <a href="tel:073761499">07376/1499</a><br />E-Mail: <a href="mailto:norbert.spoth@web.de">norbert.spoth@web.de</a>
      </p>
      {/*
      <br />
      <div className={favicon}>Weihnachtskrippe<br/>Dürrenwaldstetten</div>
        */}
    </Layout>
  )
}

export default IndexPage
